<template lang="pug">
  v-card(max-width="500" min-width="400px").auth-card
    v-card-text
      v-form(@submit.prevent="sendLoginRequest" ref="form").auth-form
        v-text-field(
          label='Username'
          name="username"
          :rules="[RULES.REQUIRED]"
          v-model="model.formData.username"
          :error="formErrors.hasError('username')"
          :error-messages="formErrors.fields.username"
        )
        v-text-field(
          label='Password'
          type="password"
          name="password"
          :rules="[RULES.REQUIRED]"
          v-model="model.formData.password"
          :error="formErrors.hasError('password')"
          :error-messages="formErrors.fields.password"
        )
        v-btn(block color="primary" type="submit" :disabled="loading").mt-4 Login
</template>

<script>
import AuthModel from "./core/auth-model"
import FormErrors from "@/util/form-errors"
import { REQUIRED } from "@/util/validation-rules";
import EventBus from "@/util/event-bus";
import { EVENT_BUS } from "@/util/const";
import ErrMixin from "@/mixins/errors.mixin"

export default {
  name: 'Login',
  mixins: [ ErrMixin ],

  data: () => ({
    model: null,
    formErrors: null,
    RULES: {
      REQUIRED
    },
    loading: false
  }),

  created() {
    this.init()
  },

  methods: {
    init() {
      this.formErrors = new FormErrors()
      this.model = new AuthModel(this.formErrors);
    },

    async sendLoginRequest() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      try {
        this.loading = true
        await this.$auth.login(this.model.getApiData())
        await this.$router.push({ name: this.$ROUTER_NAMES.RESERVATION })
      } catch (err) {
        this.processError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>